/* eslint-disable react/function-component-definition */
import React from "react";
import { Helmet } from "react-helmet";
import { AppBar, Toolbar, CssBaseline, Button } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import topBg from "../../img/appBarBg.png";
import logo from "../../img/newZims3.png";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const PendingNotice = ({ status }) => {
  const { logout } = useAuth();
  const classes = useStyles();

  // Initialize a variable to hold the message to be displayed
  let message;

  // Determine the message based on the status prop
  if (status === 1) {
    // Message for when the status is 'pending'
    message = "Your account is awaiting approval. Please check back later.";
  } else if (status === 3) {
    // Message for when the status is 'leaver'
    message =
      "Your account has been deactivated. Please contact support for further assistance.";
  } else if (status === 4) {
    // Default message for deleted status
    message = "Your account has been deleted. Please contact support.";
  } else if (status === 5) {
    // Default message for rejected status
    message =
      "Your account application has been rejected. Please contact support.";
  } else {
    // Default message for unknown status
    message = "Your account is currently inactive. Please contact support.";
  }

  return (
    <div>
      <Helmet>
        <title>Zawadi</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={classes.appBar}
        style={{
          background: `url(${topBg})`,
          backgroundSize: "cover",
          backgroundColor: "#fff",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Toolbar>
          <img
            src={logo}
            style={{ maxHeight: 50, marginLeft: ".5rem" }}
            alt="Logo"
          />
          <Typography
            variant="h6"
            noWrap
            style={{ flex: 1 }}
            className={classes.menuText}
          />
        </Toolbar>
      </AppBar>
      <div style={styles.container}>
        <p style={styles.message}>{message}</p>
      </div>
      <div style={styles.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </Button>
      </div>
      <div style={styles.buttonContainer}>
        <Button variant="contained" color="primary" onClick={logout}>
          Logout
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#f8d7da",
    color: "#721c24",
    border: "1px solid #f5c6cb",
    borderRadius: "5px",
    marginTop: "80px",
  },
  buttonContainer: {
    marginTop: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  message: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default PendingNotice;
