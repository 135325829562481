import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import nanoId from "nano-id";
import Markdown from "react-markdown";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { pageTitleStyles } from "../../../../../mui/styles";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useCompany } from "../../../../../contexts/CompanyContext";
import { supabase } from "../../../../../supabase";
import {
  // salesLeadCategories,
  salesLeadPaymentTypes,
} from "../../../../shared/constants";

import SBFileUploader from "../../../../shared/SBFileUploader";
import ModalCard from "../../../../shared/ModalCard";

export default function AddLeadModal({ supplier, onClose }) {
  const uploadBucket = "sales_assist";
  const { currentUser } = useAuth();
  const { getSupplier } = useCompany();

  const defFormValues = {
    platform: "App",
    dealer_code: currentUser.dealer_code,
    supplier,
    address: "",
    phone_number: "",
    alternative_number: "",
    customer_name: "",
    ref_number: "",
    agent_note: "",
    note: "",
    agreed_to_terms: false,
    gps_location: "",
    email_address: "",
    // category: "",
    status: "Open",
    payment_type: "",
    id_number: "",
    last_updated_by_name: "New",
    supporting_document_ref: "",
  };

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [addSupportingDocsModal, setAddSupportingDocsModal] = useState(false);
  const [isFilesUploaded, setIsFilesUploaded] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [uploadId, setUploadId] = useState("");
  const [formValues, setFormValues] = useState(defFormValues);
  const [supplierText, setSupplierText] = useState(undefined);

  // gps location

  const getLocation = () => {
    const successCallback = async position => {
      const { coords } = await position;
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        gps_location: `${coords.latitude} ${coords.longitude}`,
      }));
    };

    const errorCallback = error => {
      console.error(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  // form changes

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;
    if (name === "agreed_to_terms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreed_to_terms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  // Handle selects

  // const handleCategorySelect = event => {
  //   setDisable(false);
  //   setFormValues(prev => ({ ...prev, category: event.target.value }));
  // };

  const handlePaymentTypeSelect = event => {
    setDisable(false);
    setFormValues(prev => ({ ...prev, payment_type: event.target.value }));
  };

  //

  const handleIsFilesUploaded = () => setIsFilesUploaded(true);

  const toggleAddSupportingDocsModal = () => {
    if (formValid()) {
      setAddSupportingDocsModal(old => !old);
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
    }
  };

  const formValid = () => {
    return (
      // formValues.category &&
      formValues.customer_name &&
      formValues.address &&
      formValues.phone_number &&
      formValues.email_address &&
      formValues.id_number &&
      formValues.agreed_to_terms
    );
  };

  const addSalesLead = async () => {
    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .insert(formValues)
      .select();

    if (error) {
      setErrorMessage("Error creating entry");
      setIsSnackbarOpen(true);
      console.error(error);
    }
  };

  const handleAddLead = async () => {
    if (formValid()) {
      try {
        // await addSalesLead({ ...formValues });
        await addSalesLead();
        setFormValues(defFormValues);
        onClose();
      } catch (err) {
        setErrorMessage(err);
        setIsSnackbarOpen(true);
      }
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
      setDisable(true);
    }
  };

  const fetchUploadedFiles = async () => {
    const { data: files, error } = await supabase.storage
      .from(uploadBucket)
      .list(uploadId);

    if (error) {
      throw error;
    }

    if (!files || !files.length) {
      // eslint-disable-next-line no-alert
      alert("No Files to upload");
      setFilesUploaded([]);
      setIsFilesUploaded(false);
      setFormValues(old => ({ ...old, supporting_document_ref: "" }));
    } else {
      setFilesUploaded(files);
      setFormValues(old => ({ ...old, supporting_document_ref: uploadId }));
    }
  };

  const fetchSupplierText = async () => {
    const supplierDetail = await getSupplier(supplier);
    setSupplierText(await supplierDetail.sales_assist_text);
  };

  useEffect(() => {
    if (isFilesUploaded) {
      fetchUploadedFiles();
    }
  }, [addSupportingDocsModal]);

  useEffect(() => {
    setUploadId(nanoId());
    fetchSupplierText();
  }, []);

  const viewFiles = filesUploaded.map(file => {
    const handleDelete = async () => {
      await supabase.storage
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(uploadBucket)
        .remove([`${uploadId}/${file.name}`]);
      fetchUploadedFiles();
    };

    return (
      <Grid item xs={12} key={file.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{file.name}</code>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button color="secondary" onClick={handleDelete}>
              <DeleteForeverIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <div>
      <h1 style={pageTitleStyles}>Add Lead</h1>
      <Grid container xs={12} spacing={2}>
        {/* to use in agent assist */}
        {/* <Grid item xs={12}>
          <TextField
            required
            select
            value={formValues.category}
            onChange={handleCategorySelect}
            id="outlined-required"
            label="Category"
            variant="outlined"
            helperText="Select the lead category"
            fullWidth
          >
            {salesLeadCategories[
              salesLeadCategories[formValues.supplier]
                ? formValues.supplier
                : "def"
            ].map(category => (
              <MenuItem key={category.key} value={category.value}>
                {category.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        {supplierText && (
          <>
            <Grid item xs={12}>
              <Markdown>{supplierText}</Markdown>
            </Grid>
            <br />
          </>
        )}
        <Grid item xs={12}>
          <TextField
            label="Customer Name"
            variant="outlined"
            required
            name="customer_name"
            value={formValues.customer_name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Customer Address"
            variant="outlined"
            required
            name="address"
            value={formValues.address}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Customer Phone Number"
            variant="outlined"
            required
            name="phone_number"
            value={formValues.phone_number}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Customer Whatsapp Number"
            variant="outlined"
            name="alternative_number"
            value={formValues.alternative_number}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Customer Email Address"
            variant="outlined"
            name="email_address"
            value={formValues.email_address}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Customer ID Number"
            variant="outlined"
            name="id_number"
            value={formValues.id_number}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        {salesLeadPaymentTypes[formValues.supplier] && (
          <Grid item xs={12}>
            <TextField
              required
              select
              value={formValues.payment_type}
              onChange={handlePaymentTypeSelect}
              id="outlined-required"
              label="Payment Type"
              variant="outlined"
              helperText="Select the Payment type"
              fullWidth
            >
              {formValues.supplier &&
                salesLeadPaymentTypes[formValues.supplier].map(type => (
                  <MenuItem key={type.key} value={type.value}>
                    {type.value}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        )}
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              label="Customer GPS Location"
              variant="outlined"
              name="gps_location"
              value={formValues.gps_location}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} alignContent="center">
            <Button
              color="primary"
              onClick={getLocation}
              disabled={disable}
              fullWidth
            >
              <LocationOnOutlinedIcon /> Get Location
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Additional Notes"
              variant="outlined"
              name="agent_note"
              value={formValues.agent_note}
              onChange={handleChange}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography variant="body2">
              *Please note that utilizing the sales assist function does not
              guarantee the finalization, payment, or installation of an order.
              The purpose of utilizing the sales assist function is solely for
              assistance.
            </Typography>
            <br />
            <Typography variant="body2">
              *When using the sales assist function, please ensure to input the
              client's details in the designated spaces. Tickets with agent's
              personal information will be closed without further attention.
            </Typography>
            <br />
            <Typography variant="subtitle2">
              *Service Level Agreement (SLA) Times: We are currently unable to
              provide precise SLA times. However, rest assured, we will provide
              updates and feedback as soon as possible.
            </Typography>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Agreed To Terms"
              name="agreed_to_terms"
              checked={formValues.agreed_to_terms}
              onChange={handleChange}
            />
          </Grid>
          {isFilesUploaded ? (
            <Grid item container direction="rows">
              {viewFiles}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="caption">No Files Uploaded</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={toggleAddSupportingDocsModal}
              disabled={disable}
            >
              Add Supporting Documents
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAddLead}
            disabled={disable}
          >
            Submit
          </Button>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={4000}
            onClose={() => setIsSnackbarOpen(false)}
          >
            <Alert severity="error" onClose={() => setIsSnackbarOpen(false)}>
              {errorMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
      <ModalCard
        open={addSupportingDocsModal}
        onClose={toggleAddSupportingDocsModal}
      >
        <SBFileUploader
          bucketName={uploadBucket}
          folderName={uploadId}
          onClose={toggleAddSupportingDocsModal}
          withSubmit={handleIsFilesUploaded}
        />
      </ModalCard>
    </div>
  );
}
