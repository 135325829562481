// #useAuthUpdated
import {
  Button,
  Grid,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Box,
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { pageTitleStyles } from "../../../../mui/styles";
import MobileLeaderBoard from "./MobileLeaderBoard";
import OverallDesk from "./OverallDesk";
import StructureDesk from "./StructureDesk";
import { useAuth } from "../../../../contexts/AuthContext";
import { useCompany } from "../../../../contexts/CompanyContext";
import { useSB } from "../../../../contexts/SupabaseContext";
import { supabase } from "../../../../supabase";
import Loading from "../../../Loading";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#F0BD44",
  },
  card1: {
    backgroundColor: "#267353",
    color: "#FFFFFF",
  },

  TextColor: {
    color: "#FFFFFF",
  },

  button1: {
    color: "#267353",
  },
  button2: {
    backgroundColor: "#FFFFFF",
  },
  buttonSize: {
    width: 120,
  },
  buttonSize2: {
    width: 120,
  },
  selected: {
    backgroundColor: "#267353",
    color: "white",
    width: 120,
  },
  notSelected: {
    color: "white",
    width: 120,
  },
});

export default function LeaderBoard() {
  const [selectedButton, setSelectedButton] = useState("Overall");
  const { GetTableWhere } = useSB();
  const { suppliers, productTypes } = useCompany();
  const [Leaders, setLeaders] = useState([]);
  const [StructureData, setStructureData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const classes = useStyles();
  const currentYM = moment().format("YYYY-MM");
  const [selectedSuppliers, setSelectedSuppliers] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");
  const [downstream, setDownstream] = useState([{}]);
  const [leaderboardDate, setLeaderboardDate] = useState(currentYM);

  const start_d = `${leaderboardDate}-01`;
  const end_d = moment(start_d).endOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetTableWhere("dealer_downstream", [
          "dealer_id",
          "eq",
          currentUser.id,
        ]);
        setDownstream(data[0].downstream_uids);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [selectedOption, leaderboardDate, selectedSuppliers]);

  useEffect(() => {
    async function fetchLeaders() {
      setLoading(true);
      const query = supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("leaderboard_v2")
        .select(
          "*, dealers(contact_name, contact_surname), suppliers(supplier)"
        )
        .gte("start_d", start_d)
        .lte("start_d", end_d)
        .order("sales", { ascending: false });

      if (selectedSuppliers !== "all") {
        query.eq("supplier", selectedSuppliers);
      }

      const { data, error } = await query;
      if (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        return;
      }

      const leaders = data
        .filter(item => item.dealer_id)
        .reduce((result, item) => {
          const existing = result.find(r => r.dealer_id === item.dealer_id);
          if (existing) existing.sales += item.sales || 0;
          else result.push(item);
          return result;
        }, [])
        .sort((a, b) => b.sales - a.sales)
        .map((item, index) => ({
          ...item,
          rank: index,
          p: ((item.sales / data[0].sales) * 100).toFixed(2),
        }));

      setLeaders(leaders);
      setLoading(false);
    }

    fetchLeaders();
  }, [leaderboardDate, selectedSuppliers]);

  useEffect(() => {
    const filteredLeaders = Leaders.filter(leader =>
      downstream.toString().includes(leader.dealer_id)
    );
    setStructureData(filteredLeaders);
    setLoading(false);
  }, [downstream, Leaders, selectedSuppliers]);

  const handleDateButtons = direction => {
    setLoading(true);
    const newDate = moment(leaderboardDate)
      .add(direction === ">" ? 1 : -1, "month")
      .format("YYYY-MM");
    setLeaderboardDate(newDate);
    setLoading(false);
  };

  const handleChangeSuppliers = e => setSelectedSuppliers(e.target.value);
  const handleChange = e => setSelectedOption(e.target.value);
  const handleClick = id => setSelectedButton(id);

  return (
    <div>
      {!loading ? (
        selectedButton === "Overall" ? (
          <>
            <Typography
              variant="h4"
              style={{ ...pageTitleStyles, fontWeight: "550" }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                    flex: 1,
                    marginBottom: "1rem",
                  }}
                >
                  Leaderboard
                </Box>
                <Grid item>
                  <Grid container direction="row">
                    {" "}
                    <Hidden xsDown>
                      <Grid style={{ paddingRight: 10 }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: 160, marginTop: 5, marginRight: 10 }}
                          size="small"
                        >
                          <InputLabel
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Select a Supplier
                          </InputLabel>
                          <Select
                            label="Select a Supplier"
                            value={selectedSuppliers}
                            onChange={handleChangeSuppliers}
                            style={{
                              height: 38,
                              marginTop: -2,
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            <MenuItem value="all">All Suppliers</MenuItem>
                            {suppliers.map(supplier => {
                              return supplier.supplier_status === "Active" ? (
                                <MenuItem value={supplier.id} key={supplier.id}>
                                  {supplier.supplier}
                                </MenuItem>
                              ) : null;
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          disabled
                          variant="outlined"
                          size="small"
                          style={{ width: 160, marginTop: 5 }}
                        >
                          <InputLabel style={{ fontSize: "12px" }}>
                            Select a Product Type
                          </InputLabel>
                          <Select
                            label="Select a Product Type"
                            value={selectedOption}
                            onChange={handleChange}
                            style={{
                              height: 38,
                              marginTop: -2,
                              fontSize: "12px",
                            }}
                          >
                            <MenuItem value="">All</MenuItem>
                            {productTypes.map(productType => {
                              return (
                                <MenuItem
                                  value={productType.id}
                                  key={productType.id}
                                >
                                  {productType.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons("<")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          PREV
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons(">")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                          disabled={leaderboardDate === currentYM}
                        >
                          NEXT
                        </Button>
                      </Grid>
                    </Hidden>{" "}
                    <Hidden smUp>
                      <Grid
                        container
                        // Add spacing between Grid items
                        justifyContent="center" // Center horizontally
                        alignself="center" // Center vertically
                      >
                        <Grid item xs={12} sm={6}>
                          {" "}
                          {/* Adjust width on larger screens */}
                          <FormControl
                            variant="outlined"
                            style={{ marginTop: 5, width: "100%" }}
                          >
                            <InputLabel
                              style={{
                                fontSize: "12px",
                                width: "100%",
                              }}
                            >
                              Select a Supplier
                            </InputLabel>
                            <Select
                              label="Select a Supplier"
                              value={selectedSuppliers}
                              onChange={handleChangeSuppliers}
                              style={{
                                height: 38,
                                marginTop: -2,
                                fontSize: "12px",
                              }}
                            >
                              <MenuItem value="all">All Suppliers</MenuItem>
                              {suppliers.map(supplier => {
                                return supplier.supplier_status === "Active" ? (
                                  <MenuItem
                                    value={supplier.id}
                                    key={supplier.id}
                                  >
                                    {supplier.supplier}
                                  </MenuItem>
                                ) : null;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {" "}
                          <FormControl
                            disabled
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: "1rem" }}
                          >
                            <InputLabel style={{ fontSize: "12px" }}>
                              Select a Product Type
                            </InputLabel>
                            <Select
                              label="Select a Product Type"
                              value={selectedOption}
                              onChange={handleChange}
                              style={{
                                height: 38,
                                marginTop: -2,
                                fontSize: "12px",
                              }}
                            >
                              <MenuItem value="">All</MenuItem>
                              {productTypes.map(productType => {
                                return (
                                  <MenuItem
                                    value={productType.id}
                                    key={productType.id}
                                  >
                                    {productType.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          sx={{
                            "@media (max-width: 600px)": {
                              flexDirection: "column", // Switch to column on mobile
                            },
                          }}
                        >
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDateButtons("<")}
                              fullWidth
                              style={{ marginTop: "1rem" }}
                            >
                              PREV
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDateButtons(">")}
                              fullWidth
                              style={{ marginTop: "1rem" }}
                              disabled={leaderboardDate === currentYM}
                            >
                              NEXT
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                    {currentUser.rank !== 8 ? (
                      <Hidden only={["xs"]}>
                        <Grid item style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonSize2}
                            onClick={() => handleClick("Overall")}
                            style={{
                              marginRight: 10,
                              borderRadius: "10px", // Set the card rounding
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Overall
                          </Button>

                          {StructureData !== undefined ? (
                            <Button
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                              style={{
                                marginRight: 10,
                                borderRadius: "10px", // Set the card rounding
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              Structure
                            </Button>
                          ) : (
                            <Button
                              disabled
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                              style={{
                                marginRight: 10,
                                borderRadius: "10px", // Set the card rounding
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              Structure
                            </Button>
                          )}
                        </Grid>
                      </Hidden>
                    ) : (
                      <Hidden only={["xs"]}>
                        <Grid md={7} lg={9} />
                        <Grid item xs={2} lg={1} />
                        <Grid item xs={1} />
                      </Hidden>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
            <Alert severity="info" style={{ marginBottom: "20px" }}>
              {Leaders.length > 0
                ? //     ? leaderboardDate === currentYM
                  `Updated Until: 
              ${
                Leaders[0]?.end_d?.length > 10
                  ? Leaders[0].end_d.slice(0, 10)
                  : `${!loading ? "No Data" : "Loading..."}`
              }`
                : //     : `Leader board for: ${leaderboardDate}`
                  "The Leaderboard has not been updated for this month yet."}
            </Alert>
            {Leaders.length === 0 ? (
              <h2>
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </h2>
            ) : (
              <div>
                {Leaders.length > 0 ? (
                  <>
                    <Hidden only={["xs"]}>
                      <OverallDesk Leaders={Leaders} />
                    </Hidden>
                    <Hidden only={["xl", "lg", "md", "sm"]}>
                      <MobileLeaderBoard
                        Leaders={Leaders}
                        StructureData={StructureData}
                      />
                    </Hidden>
                  </>
                ) : (
                  <h1>
                    The Leaderboard has not been updated for this month yet.
                  </h1>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              style={{ ...pageTitleStyles, fontWeight: "bold" }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                    flex: 1,
                  }}
                >
                  Leaderboard
                </Box>
                <Grid item>
                  <Grid container direction="row">
                    <Grid style={{ paddingRight: 10 }}>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: 191,
                          marginTop: 5,
                          marginRight: 10,
                        }}
                        size="small"
                      >
                        <InputLabel
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Select a Supplier
                        </InputLabel>
                        <Select
                          label="Select a Supplier"
                          value={selectedSuppliers}
                          onChange={handleChangeSuppliers}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          <MenuItem value="all">All Suppliers</MenuItem>
                          {suppliers.map(supplier => {
                            return supplier.supplier_status === "Active" ? (
                              <MenuItem value={supplier.id} key={supplier.id}>
                                {supplier.supplier}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        disabled
                        variant="outlined"
                        size="small"
                        style={{ width: 192, marginTop: 5 }}
                      >
                        <InputLabel style={{ fontSize: "12px" }}>
                          Select a Product Type
                        </InputLabel>
                        <Select
                          label="Select a Product Type"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          <MenuItem value="">All</MenuItem>
                          {productTypes.map(productType => {
                            return (
                              <MenuItem
                                value={productType.id}
                                key={productType.id}
                              >
                                {productType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Hidden smUp>
                      {" "}
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                          "@media (max-width: 600px)": {
                            flexDirection: "column", // Switch to column on mobile
                          },
                        }}
                      >
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons("<")}
                            fullWidth
                            style={{ marginTop: "1rem" }}
                          >
                            PREV
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons(">")}
                            fullWidth
                            style={{ marginTop: "1rem" }}
                            disabled={leaderboardDate === currentYM}
                          >
                            NEXT
                          </Button>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden only={["xs"]}>
                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons("<")}
                          style={{ marginRight: 10 }}
                        >
                          PREV
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons(">")}
                          style={{
                            marginRight: 10,
                          }}
                          disabled={leaderboardDate === currentYM}
                        >
                          NEXT
                        </Button>
                      </Grid>
                    </Hidden>

                    <Hidden only={["xs"]}>
                      <Grid itme style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Overall")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Overall
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#F0BD10",
                            color: "white",
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Structure")}
                        >
                          Structure
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
            <Alert severity="info" style={{ marginBottom: "20px" }}>
              {Leaders.length > 0
                ? //    ? leaderboardDate === currentYM
                  `Updated Until: 
              ${
                Leaders[0]?.end_d?.length > 10
                  ? Leaders[0].end_d.slice(0, 10)
                  : `${!loading ? "No Data" : "Loading..."}`
              }`
                : //   : `Leader board for: ${leaderboardDate}`
                  "The Leaderboard has not been updated for this month yet."}
            </Alert>
            {Leaders.length === 0 ? (
              <h2>
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </h2>
            ) : (
              <div>
                <Hidden only={["xs"]}>
                  <StructureDesk Leaders={StructureData} />
                </Hidden>
                <Hidden only={["xl", "lg", "md", "sm"]}>
                  <MobileLeaderBoard
                    Leaders={Leaders}
                    StructureData={StructureData}
                  />
                </Hidden>
              </div>
            )}
          </>
        )
      ) : (
        <div>
          <Loading loading={loading} />
        </div>
      )}
    </div>
  );
}
