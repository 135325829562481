import React, { useState } from "react";
import { Button } from "@material-ui/core";
import exportToCsv from "../../../../shared/functions/generateCsv";

/**
 * AgentReport is a component that displays a report for agents with order statistics, a table of order data,
 * and the ability to download the report as a CSV file. It also provides a modal to view detailed customer information.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.data - The data for the report, typically an array of order objects.
 * @param {boolean} props.loading - A flag indicating if the report is loading data.
 * @param {Array<{label: string, value: number}>} props.tiles - An array of objects representing summary statistics
 *        to be displayed as tiles, where each object contains a `label` and a `value`.
 *
 * @returns {JSX.Element} - The rendered component.
 */

function AgentReport({ data = [], loading = false, tiles = [] }) {
  // State to control the visibility of the customer detail modal
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  // State to hold the data of the selected customer for display in the modal
  const [customerData, setCustomerData] = useState([]);

  // Map over the tiles array to create the display tiles for summary statistics
  const tileMap = tiles.map(item => (
    <div
      style={{
        marginRight: "3%",
        justifyContent: "center",
        textAlign: "center",
      }}
      key={item.label}
    >
      <p style={{ fontSize: "1rem" }}>{item.label}</p>
      <p style={{ fontSize: "1.8rem", marginTop: 0 }}>{item.value}</p>
    </div>
  ));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: 700,
        }}
      >
        {!loading ? (
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: 700,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                {/* Render the order statistics */}
                {tileMap}
              </div>
            </div>
            {/* Render the table of order data */}
            <table style={{ borderCollapse: "collapse", margin: "auto" }}>
              <thead>
                <tr style={{ borderBottom: "2px solid green" }}>
                  <th>Order Number</th>
                  <th>Created Date</th>
                  <th>Paid Date</th>
                  <th>Activation Date</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {data &&
                  data.map(item => (
                    <tr
                      onClick={() => {
                        setShowCustomerModal(true);
                        setCustomerData(item);
                      }}
                      style={{
                        borderBottom: "1px solid grey",
                        cursor: "pointer",
                        backgroundColor:
                          item.order_paid_date_1 !== null &&
                          item.order_activation_date_2 == null
                            ? "rgba(255, 186, 14, 1)" // yellow
                            : item.order_activation_date_2 !== null
                            ? "rgba(38, 115, 83, 1)" // green
                            : item.order_paid_date_1 === null &&
                              item.order_activation_date_2 === null
                            ? "rgba(217, 77, 2, 1)" // red
                            : "rgba(255, 255, 255, 1)", // default color
                      }}
                      key={item.order_number_1}
                    >
                      <td style={{ padding: "10px", color: "white" }}>
                        {item.order_number_1}
                      </td>
                      <td style={{ padding: "10px", color: "white" }}>
                        {item.order_created_date_1}
                      </td>
                      <td style={{ padding: "10px", color: "white" }}>
                        {item.order_paid_date_1}
                      </td>
                      <td style={{ padding: "10px", color: "white" }}>
                        {item.order_activation_date_2}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ margin: "auto" }}>
                <h2>Click on one of the orders to display more Information</h2>
              </div>
              {/* Button to download the report as a CSV file */}
              <div style={{ margin: "auto" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 20, marginBottom: 20 }}
                  onClick={() => exportToCsv(data, "Agent Report")}
                >
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 300,
              fontSize: "24px",
            }}
          >
            Loading...
          </div>
        )}
        {/* Modal to display detailed customer information */}
        {showCustomerModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                top: "10%",
                backgroundColor: "white",
                padding: "3%",
                borderRadius: "10px",
                textAlign: "center",
                maxHeight: "80%",
                overflow: "auto",
              }}
            >
              <p style={{ fontSize: 26 }}>Customer Information</p>
              <p style={{ fontWeight: "bold" }}>Address:</p>{" "}
              <p>{customerData.customer_details_address_0}</p>
              <p style={{ fontWeight: "bold" }}>Contact Name:</p>{" "}
              <p>{customerData.customer_details_name_0}</p>
              <p style={{ fontWeight: "bold" }}>Contact Number:</p>{" "}
              <p>
                {customerData.customer_details_phone_0.length === 9
                  ? `0${customerData.customer_details_phone_0}`
                  : "Not Available"}
              </p>
              {customerData.customer_details_phone_0.length === 9 && (
                <button
                  type="button"
                  style={{
                    backgroundColor: "#267353",
                    color: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    border: 0,
                  }}
                  onClick={() => {
                    window.open(
                      `https://wa.me/+27${customerData.customer_details_phone_0}`,
                      "_blank"
                    );
                  }}
                >
                  Whatsapp customer
                </button>
              )}
              <p style={{ fontWeight: "bold" }}>Order Created Date:</p>{" "}
              <p>{customerData.order_created_date_1}</p>
              <p style={{ fontWeight: "bold" }}>Payment Received Date:</p>{" "}
              <p>{customerData.order_paid_date_1}</p>
              <p style={{ fontWeight: "bold" }}>Order Installed Date:</p>{" "}
              <p>{customerData.order_installation_date_1}</p>
              <p style={{ fontWeight: "bold" }}>Order Activation Date:</p>{" "}
              <p>{customerData.order_activation_date_2}</p>
              {customerData.customer_onboarding_status_0 && (
                <>
                  <p style={{ fontWeight: "bold" }}>Activation Status:</p>
                  <p>{customerData.customer_onboarding_status_0}</p>
                </>
              )}
              {customerData.customer_current_status_0 && (
                <>
                  <p style={{ fontWeight: "bold" }}>Current Status:</p>
                  <p>{customerData.customer_current_status_0}</p>
                </>
              )}
              <button
                type="button"
                style={{
                  backgroundColor: "#267353",
                  color: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  border: 0,
                }}
                onClick={() => setShowCustomerModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AgentReport;
