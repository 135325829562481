export const months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const years = [
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
];

export const networks = [
  { value: "mtn", label: "MTN" },
  { value: "vodacom", label: "Vodacom" },
  { value: "telkom", label: "Telkom" },
];

export const agentLevelOptions = [
  { label: "Admin (1)", value: 1 },
  { label: "Super Dealer (2)", value: 2 },
  { label: "Dealer (3)", value: 3 },
  { label: "Sales Manager (4)", value: 4 },
  { label: "Tier 2 Team Leader (5)", value: 5 },
  { label: "Tier 1 Team Leader (6)", value: 6 },
  { label: "Assistant Team Leader (7)", value: 7 },
  { label: "Agent (8)", value: 8 },
];

export const selectFieldProps = {
  menuPortalTarget: document.body,
  styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
};

export const textFieldProps = {
  variant: "outlined",
  fullWidth: true,
  style: { marginBottom: 10 },
};

export const provinces = [
  { label: "Eastern Cape", value: "Eastern Cape" },
  { label: "Free State", value: "Free State" },
  { label: "Gauteng", value: "Gauteng" },
  { label: "KwaZulu Natal", value: "KwaZulu Natal" },
  { label: "Limpopo", value: "Limpopo" },
  { label: "Mpumalanga", value: "Mpumalanga" },
  { label: "North West", value: "North West" },
  { label: "Northern Cape", value: "Northern Cape" },
  { label: "Western Cape", value: "Western Cape" },
];

export const idTypes = [
  { label: "SA ID", value: "N" },
  { label: "Passport (P)", value: "Passport (P)" },
];

export const mtnDefaultSchema = {
  activated: false,
  connected: false,
  connectionYear: null,
  connectionMonth: null,
  activationYear: null,
  activationMonth: null,
  allocationKey: null,
};

export const vcDefaultSchema = {
  connectionYear: null,
  connectionMonth: null,
  activationYear: null,
  activationMonth: null,
  allocationKey: null,
  msisdn: null,
};

export const telkomDefaultSchema = {
  connectionYear: null,
  connectionMonth: null,
  activationYear: null,
  activationMonth: null,
  msisdn: null,
  allocationKey: null,
};

export const cellCDefaultSchema = {
  msisdn: null,
  consign: null,
  allocationKey: null,
  tags: null,
  rica: null,
  first_recharge: null,
  activation: null,
  hvt1: null,
  hvt2: null,
  hvt3: null,
};

export const mtnActivationSchema = {
  sim: null,
  serial_number: null,
  msisdn: null,
  activationYear: null,
  activationMonth: null,
};

export const banks = [
  { label: "ABSA", value: "ABSA", branchCode: "632005" },
  { label: "African Bank", value: "African Bank", branchCode: "430000" },
  { label: "Bank of Athens", value: "Bank of Athens", branchCode: "410506" },
  { label: "Bidvest Bank", value: "Bidvest Bank", branchCode: "462005" },
  { label: "Capitec", value: "Capitec", branchCode: "470010" },
  {
    label: "First National Bank",
    value: "First National Bank",
    branchCode: "250655",
  },
  { label: "Investec", value: "Investec", branchCode: "580105" },
  { label: "Nedbank", value: "Nedbank", branchCode: "198765" },
  { label: "SA Post Bank", value: "SA Post Bank", branchCode: "460005" },
  { label: "Standard Bank", value: "Standard Bank", branchCode: "051001" },
  { label: "Tyme Bank", value: "Tyme Bank", branchCode: "678910" },
  { label: "Discovery Bank", value: "Discovery Bank", branchCode: "679000" },
];

export const bankAccountTypes = [
  { label: "Cheque Account", value: 1 },
  { label: "Savings Account", value: 2 },
];

export const countryCodes = [
  { label: "South Africa", value: "ZAF" },
  { label: "Afghanistan", value: "AFG" },
  { label: "Albania", value: "ALB" },
  { label: "Antarctica", value: "ATA" },
  { label: "Algeria", value: "DZA" },
  { label: "American Samoa", value: "ASM" },
  { label: "Andorra", value: "AND" },
  { label: "Angola", value: "AGO" },
  { label: "Antigua and Barbuda", value: "ATG" },
  { label: "Azerbaijan", value: "AZE" },
  { label: "Argentina", value: "ARG" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Bahamas (the)", value: "BHS" },
  { label: "Bahrain", value: "BHR" },
  { label: "Bangladesh", value: "BGD" },
  { label: "Armenia", value: "ARM" },
  { label: "Barbados", value: "BRB" },
  { label: "Belgium", value: "BEL" },
  { label: "Bermuda", value: "BMU" },
  { label: "Bhutan", value: "BTN" },
  { label: "Bolivia (Plurinational State of)", value: "BOL" },
  { label: "Bosnia and Herzegovina", value: "BIH" },
  { label: "Botswana", value: "BWA" },
  { label: "Bouvet Island", value: "BVT" },
  { label: "Brazil", value: "BRA" },
  { label: "Belize", value: "BLZ" },
  { label: "British Indian Ocean Territory (the)", value: "IOT" },
  { label: "Solomon Islands", value: "SLB" },
  { label: "Virgin Islands (British)", value: "VGB" },
  { label: "Brunei Darussalam", value: "BRN" },
  { label: "Bulgaria", value: "BGR" },
  { label: "Myanmar", value: "MMR" },
  { label: "Burundi", value: "BDI" },
  { label: "Belarus", value: "BLR" },
  { label: "Cambodia", value: "KHM" },
  { label: "Cameroon", value: "CMR" },
  { label: "Canada", value: "CAN" },
  { label: "Cabo Verde", value: "CPV" },
  { label: "Cayman Islands (the)", value: "CYM" },
  { label: "Central African Republic (the)", value: "CAF" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Chad", value: "TCD" },
  { label: "Chile", value: "CHL" },
  { label: "China", value: "CHN" },
  { label: "Taiwan (Province of China)", value: "TWN" },
  { label: "Christmas Island", value: "CXR" },
  { label: "Cocos (Keeling) Islands (the)", value: "CCK" },
  { label: "Colombia", value: "COL" },
  { label: "Comoros (the)", value: "COM" },
  { label: "Mayotte", value: "MYT" },
  { label: "Congo (the)", value: "COG" },
  { label: "Congo (the Democratic Republic of the)", value: "COD" },
  { label: "Cook Islands (the)", value: "COK" },
  { label: "Costa Rica", value: "CRI" },
  { label: "Croatia", value: "HRV" },
  { label: "Cuba", value: "CUB" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czechia", value: "CZE" },
  { label: "Benin", value: "BEN" },
  { label: "Denmark", value: "DNK" },
  { label: "Dominica", value: "DMA" },
  { label: "Dominican Republic (the)", value: "DOM" },
  { label: "Ecuador", value: "ECU" },
  { label: "El Salvador", value: "SLV" },
  { label: "Equatorial Guinea", value: "GNQ" },
  { label: "Ethiopia", value: "ETH" },
  { label: "Eritrea", value: "ERI" },
  { label: "Estonia", value: "EST" },
  { label: "Faroe Islands (the)", value: "FRO" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FLK" },
  { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { label: "Fiji", value: "FJI" },
  { label: "Finland", value: "FIN" },
  { label: "Åland Islands", value: "ALA" },
  { label: "France", value: "FRA" },
  { label: "French Guiana", value: "GUF" },
  { label: "French Polynesia", value: "PYF" },
  { label: "French Southern Territories (the)", value: "ATF" },
  { label: "Djibouti", value: "DJI" },
  { label: "Gabon", value: "GAB" },
  { label: "Georgia", value: "GEO" },
  { label: "Gambia (the)", value: "GMB" },
  { label: "Palestine, State of", value: "PSE" },
  { label: "Germany", value: "DEU" },
  { label: "Ghana", value: "GHA" },
  { label: "Gibraltar", value: "GIB" },
  { label: "Kiribati", value: "KIR" },
  { label: "Greece", value: "GRC" },
  { label: "Greenland", value: "GRL" },
  { label: "Grenada", value: "GRD" },
  { label: "Guadeloupe", value: "GLP" },
  { label: "Guam", value: "GUM" },
  { label: "Guatemala", value: "GTM" },
  { label: "Guinea", value: "GIN" },
  { label: "Guyana", value: "GUY" },
  { label: "Haiti", value: "HTI" },
  { label: "Heard Island and McDonald Islands", value: "HMD" },
  { label: "Holy See (the)", value: "VAT" },
  { label: "Honduras", value: "HND" },
  { label: "Hong Kong", value: "HKG" },
  { label: "Hungary", value: "HUN" },
  { label: "Iceland", value: "ISL" },
  { label: "India", value: "IND" },
  { label: "Indonesia", value: "IDN" },
  { label: "Iran (Islamic Republic of)", value: "IRN" },
  { label: "Iraq", value: "IRQ" },
  { label: "Ireland", value: "IRL" },
  { label: "Israel", value: "ISR" },
  { label: "Italy", value: "ITA" },
  { label: "Côte d'Ivoire", value: "CIV" },
  { label: "Jamaica", value: "JAM" },
  { label: "Japan", value: "JPN" },
  { label: "Kazakhstan", value: "KAZ" },
  { label: "Jordan", value: "JOR" },
  { label: "Kenya", value: "KEN" },
  { label: "Korea (the Democratic People's Republic of)", value: "PRK" },
  { label: "Korea (the Republic of)", value: "KOR" },
  { label: "Kuwait", value: "KWT" },
  { label: "Kyrgyzstan", value: "KGZ" },
  { label: "Lao People's Democratic Republic (the)", value: "LAO" },
  { label: "Lebanon", value: "LBN" },
  { label: "Lesotho", value: "LSO" },
  { label: "Latvia", value: "LVA" },
  { label: "Liberia", value: "LBR" },
  { label: "Libya", value: "LBY" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Macao", value: "MAC" },
  { label: "Madagascar", value: "MDG" },
  { label: "Malawi", value: "MWI" },
  { label: "Malaysia", value: "MYS" },
  { label: "Maldives", value: "MDV" },
  { label: "Mali", value: "MLI" },
  { label: "Malta", value: "MLT" },
  { label: "Martinique", value: "MTQ" },
  { label: "Mauritania", value: "MRT" },
  { label: "Mauritius", value: "MUS" },
  { label: "Mexico", value: "MEX" },
  { label: "Monaco", value: "MCO" },
  { label: "Mongolia", value: "MNG" },
  { label: "Moldova (the Republic of)", value: "MDA" },
  { label: "Montenegro", value: "MNE" },
  { label: "Montserrat", value: "MSR" },
  { label: "Morocco", value: "MAR" },
  { label: "Mozambique", value: "MOZ" },
  { label: "Oman", value: "OMN" },
  { label: "Namibia", value: "NAM" },
  { label: "Nauru", value: "NRU" },
  { label: "Nepal", value: "NPL" },
  { label: "Netherlands (the)", value: "NLD" },
  { label: "Curaçao", value: "CUW" },
  { label: "Aruba", value: "ABW" },
  { label: "Sint Maarten (Dutch part)", value: "SXM" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BES" },
  { label: "New Caledonia", value: "NCL" },
  { label: "Vanuatu", value: "VUT" },
  { label: "New Zealand", value: "NZL" },
  { label: "Nicaragua", value: "NIC" },
  { label: "Niger (the)", value: "NER" },
  { label: "Nigeria", value: "NGA" },
  { label: "Niue", value: "NIU" },
  { label: "Norfolk Island", value: "NFK" },
  { label: "Norway", value: "NOR" },
  { label: "Northern Mariana Islands (the)", value: "MNP" },
  { label: "United States Minor Outlying Islands (the)", value: "UMI" },
  { label: "Micronesia (Federated States of)", value: "FSM" },
  { label: "Marshall Islands (the)", value: "MHL" },
  { label: "Palau", value: "PLW" },
  { label: "Pakistan", value: "PAK" },
  { label: "Panama", value: "PAN" },
  { label: "Papua New Guinea", value: "PNG" },
  { label: "Paraguay", value: "PRY" },
  { label: "Peru", value: "PER" },
  { label: "Philippines (the)", value: "PHL" },
  { label: "Pitcairn", value: "PCN" },
  { label: "Poland", value: "POL" },
  { label: "Portugal", value: "PRT" },
  { label: "Guinea-Bissau", value: "GNB" },
  { label: "Timor-Leste", value: "TLS" },
  { label: "Puerto Rico", value: "PRI" },
  { label: "Qatar", value: "QAT" },
  { label: "Réunion", value: "REU" },
  { label: "Romania", value: "ROU" },
  { label: "Russian Federation (the)", value: "RUS" },
  { label: "Rwanda", value: "RWA" },
  { label: "Saint Barthélemy", value: "BLM" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
  { label: "Saint Kitts and Nevis", value: "KNA" },
  { label: "Anguilla", value: "AIA" },
  { label: "Saint Lucia", value: "LCA" },
  { label: "Saint Martin (French part)", value: "MAF" },
  { label: "Saint Pierre and Miquelon", value: "SPM" },
  { label: "Saint Vincent and the Grenadines", value: "VCT" },
  { label: "San Marino", value: "SMR" },
  { label: "Sao Tome and Principe", value: "STP" },
  { label: "Saudi Arabia", value: "SAU" },
  { label: "Senegal", value: "SEN" },
  { label: "Serbia", value: "SRB" },
  { label: "Seychelles", value: "SYC" },
  { label: "Sierra Leone", value: "SLE" },
  { label: "Singapore", value: "SGP" },
  { label: "Slovakia", value: "SVK" },
  { label: "Viet Nam", value: "VNM" },
  { label: "Slovenia", value: "SVN" },
  { label: "Somalia", value: "SOM" },
  { label: "Zimbabwe", value: "ZWE" },
  { label: "Spain", value: "ESP" },
  { label: "South Sudan", value: "SSD" },
  { label: "Sudan (the)", value: "SDN" },
  { label: "Western Sahara", value: "ESH" },
  { label: "Suriname", value: "SUR" },
  { label: "Svalbard and Jan Mayen", value: "SJM" },
  { label: "Eswatini", value: "SWZ" },
  { label: "Sweden", value: "SWE" },
  { label: "Switzerland", value: "CHE" },
  { label: "Syrian Arab Republic", value: "SYR" },
  { label: "Tajikistan", value: "TJK" },
  { label: "Thailand", value: "THA" },
  { label: "Togo", value: "TGO" },
  { label: "Tokelau", value: "TKL" },
  { label: "Tonga", value: "TON" },
  { label: "Trinidad and Tobago", value: "TTO" },
  { label: "United Arab Emirates (the)", value: "ARE" },
  { label: "Tunisia", value: "TUN" },
  { label: "Turkey", value: "TUR" },
  { label: "Turkmenistan", value: "TKM" },
  { label: "Turks and Caicos Islands (the)", value: "TCA" },
  { label: "Tuvalu", value: "TUV" },
  { label: "Uganda", value: "UGA" },
  { label: "Ukraine", value: "UKR" },
  { label: "Republic of North Macedonia", value: "MKD" },
  { label: "Egypt", value: "EGY" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    value: "GBR",
  },
  { label: "Guernsey", value: "GGY" },
  { label: "Jersey", value: "JEY" },
  { label: "Isle of Man", value: "IMN" },
  { label: "Tanzania, United Republic of", value: "TZA" },
  { label: "United States of America (the)", value: "USA" },
  { label: "Virgin Islands (U.S.)", value: "VIR" },
  { label: "Burkina Faso", value: "BFA" },
  { label: "Uruguay", value: "URY" },
  { label: "Uzbekistan", value: "UZB" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VEN" },
  { label: "Wallis and Futuna", value: "WLF" },
  { label: "Samoa", value: "WSM" },
  { label: "Yemen", value: "YEM" },
  { label: "Zambia", value: "ZMB" },
];

export const efficiencyChartOptions = {
  chart: {
    height: 350,
    type: "radialBar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24,
        },
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35,
        },
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "17px",
        },
        value: {
          formatter(val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "36px",
          show: true,
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Percent"],
};

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#304758"],
    },
  },
  xaxis: {
    position: "bottom",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      fill: {
        type: "gradient",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
};

export const dealerIdTypes = [
  { label: "ID number", value: "RSAID" },
  { label: "Passport number", value: "PASSPT" },
];

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

// Sales Assist

// To update and use for AA
export const salesLeadCategories = {
  "1f95b163-7663-4acf-9041-d9d8804db396": [
    { key: 0, value: "Delayed Installations escalations" },
    { key: 1, value: "Installation Issues escalations" },
    { key: 2, value: "Funds transfer" },
    { key: 3, value: "Amend Address" },
    { key: 4, value: "Payment not reflecting" },
    { key: 5, value: "Sales lead" },
  ],
  "4aea65f9-1867-4aee-af37-ff0fa041ca60": [{ key: 0, value: "Sales Lead" }],
  def: [{ key: 0, value: "Sales Lead" }],
};

// to keep for SA
export const salesLeadPaymentTypes = {
  "1f95b163-7663-4acf-9041-d9d8804db396": [
    { key: 0, value: "EasyPay - once off 20/10" },
    { key: 1, value: "Debit Order - recurring 20/10" },
    { key: 2, value: "EasyPay - once off 40/10" },
    { key: 3, value: "Debit Order - recurring 40/10" },
    { key: 4, value: "EasyPay - once off 100/50" },
    { key: 5, value: "Debit Order - recurring 100/50" },
    {
      key: 6,
      value: "EasyPay once off - 10/10 *SUBJECT TO AVAILABILITY",
    },
    {
      key: 7,
      value: "Debit Order - recurring 10/10 *SUBJECT TO AVAILABILITY",
    },
  ],
  "4aea65f9-1867-4aee-af37-ff0fa041ca60": [{ key: 0, value: "EasyPay" }],
};

// to keep for SA
// standard
export const salesLeadStatuses = [
  { key: 0, value: "Open" },
  { key: 1, value: "In Progress" },
  { key: 2, value: "Closed" },
];

// to keep for SA
export const salesLeadInProgressStatuses = {
  def: [
    { key: 0, value: "Customer contacted" },
    { key: 1, value: "Order placed - Awaiting payment" },
    { key: 2, value: "Order paid" },
    { key: 3, value: "Order placed by agent" },
  ],
};

// to keep for SA
export const salesLeadClosedStatuses = {
  "1f95b163-7663-4acf-9041-d9d8804db396": [
    { key: 0, value: "No coverage" },
    { key: 1, value: "Not a Vuma reach area" },
    { key: 2, value: "Inflight" },
    { key: 3, value: "Order reallocated" },
    { key: 4, value: "Installed" },
    { key: 5, value: "Duplicate ticket" },
  ],
  def: [
    { key: 0, value: "No coverage" },
    { key: 2, value: "Inflight" },
    { key: 3, value: "Order reallocated" },
    { key: 4, value: "Installed" },
    { key: 5, value: "Duplicate ticket" },
  ],
};
