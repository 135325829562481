import React from "react";
import SubDealerManage from "../dealercomps/SubDealerManage";
import SubDealerDetail from "../dealercomps/subdealers/SubDealerDetail";
import PrivateRoute from "./PrivateRoute";
import DealersDownstream from "../dealercomps/subdealers/DealersDownstream";
import DealersChildDownstream from "../dealercomps/subdealers/DealersChildDownstream";
import DealerRequestApprovals from "../dealercomps/approvals/DealerRequestApprovals";
import DealerApprovalDetails from "../dealercomps/approvals/DealerApprovalDetails";
import LeadManagement from "../dealercomps/agent-dashboard/dash-components/LeadManagement";
import ActiveOrders from "../dealercomps/agent-dashboard/dash-components/ActiveOrders";
import SelectDash from "../dealercomps/agent-dashboard/SelectDash";
import LeaderBoard from "../dealercomps/agent-dashboard/LeaderBoard/LeaderBoard";
import ScriptTag from "../dealercomps/agent-dashboard/dash-components/zohoAsap";
import Supplier from "../dealercomps/agent-dashboard/dash-components/Supplier";
import TrainingContentViewer from "../training/TrainingContentViewer";
import TrainingAttempt from "../training/TrainingAttempt";
import GlobalAlerts from "../global-alerts/GlobalAlerts";
import AlertsList from "../global-alerts/AlertsList";
import CreateAlert from "../global-alerts/CreateAlert";
import UpdatePassword from "../authenticate/UpdatePassword";

export default function DealerRoutes() {
  const dashboard = SelectDash;

  return (
    <>
      <PrivateRoute exact path="/" component={dashboard} />
      <PrivateRoute exact path="/manage-dealers" component={SubDealerManage} />
      <PrivateRoute
        path="/dealer-detail/:dealerId"
        component={SubDealerDetail}
      />
      <PrivateRoute
        path="/dealer-downstream/:dealerId"
        component={DealersDownstream}
      />
      <PrivateRoute
        path="/child-dealer-downstream/:dealerId"
        component={DealersChildDownstream}
      />
      {/* Dealer Approvals */}
      <PrivateRoute
        exact
        path="/approvals"
        component={DealerRequestApprovals}
      />
      <PrivateRoute
        exact
        path="/approval-detail/:id"
        component={DealerApprovalDetails}
      />

      {/* Training Videos */}
      <PrivateRoute exact path="/training" component={TrainingContentViewer} />
      <PrivateRoute
        exact
        path="/training-attempt/:id"
        component={TrainingAttempt}
      />
      <PrivateRoute exact path="/select-dash" component={SelectDash} />
      <PrivateRoute
        exact
        path="/agent-dash/lead-management"
        component={LeadManagement}
      />
      <PrivateRoute
        exact
        path="/agent-dash/active-orders"
        component={ActiveOrders}
      />
      <PrivateRoute
        exact
        path="/agent-dash/leaderBoard"
        component={LeaderBoard}
      />
      <PrivateRoute exact path="/alerts" component={GlobalAlerts} />
      <PrivateRoute exact path="/alerts/view" component={AlertsList} />
      <PrivateRoute exact path="/alerts/:id/edit" component={CreateAlert} />
      <PrivateRoute exact path="/alerts/create" component={CreateAlert} />
      <PrivateRoute exact path="/Supplier/:supplierId" component={Supplier} />
      <PrivateRoute exact path="/update-password" component={UpdatePassword} />
      <ScriptTag
        path="/agent-dash"
        src="https://desk.zoho.com/portal/api/web/inapp/808641000000844029?orgId=796160664"
      />
    </>
  );
}
