/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
import { Button, LinearProgress, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Importer, ImporterField } from "react-csv-importer";
import Select from "react-select";
import "react-csv-importer/dist/index.css";
import { useDb } from "../../contexts/DatabaseContext";
import { useCompany } from "../../contexts/CompanyContext";

export default function ImportFibreReports() {
  const history = useHistory();
  const [importArray, setImportArray] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { productTypes, suppliers, products } = useCompany();
  const [formattedProductTypes, setFormattedProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState([]);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);

  const [loading, setLoading] = useState(false);

  const tempArray = [];

  const { uploadGenericFibreCsv } = useDb();
  useEffect(() => {
    // Initialize an empty array to store formatted product types
    const formattedProductTypesArray = [];

    // Iterate over each product type and create a formatted object with label and value properties
    productTypes.forEach(type => {
      formattedProductTypesArray.push({
        label: type.name, // Set the label property to the product type's name
        value: type.id, // Set the value property to the product type's ID
      });
    });

    // Update the state with the formatted product types array
    setFormattedProductTypes(formattedProductTypesArray);

    // Run this effect whenever productTypes changes
  }, [productTypes]);

  useEffect(() => {
    // Call the function to set up the supplier list when selectedProductType changes
    setUpSupplierList();
  }, [selectedProductType]);

  const setUpSupplierList = () => {
    // Reset the available suppliers list and the selected supplier
    setAvailableSuppliers([]);
    setSelectedSupplier(null);

    // Filter products that match the selected product type
    const filteredProducts = products.filter(product =>
      selectedProductType?.includes(product.product_type)
    );

    // Extract supplier IDs from the filtered products
    const supplierIds = filteredProducts.map(product => product.supplier_id);

    // Filter suppliers that match the extracted supplier IDs
    const filteredSuppliers = suppliers.filter(supplier =>
      supplierIds.includes(supplier.id)
    );

    // Initialize an empty array to store formatted suppliers
    const supplierArray = [];

    // Iterate over each filtered supplier and create a formatted object with label and value properties
    filteredSuppliers.forEach(supplier => {
      supplierArray.push({
        label: supplier.supplier, // Set the label property to the supplier's name
        value: supplier.id, // Set the value property to the supplier's ID
      });
    });

    // Update the state with the formatted supplier array
    setAvailableSuppliers(supplierArray);
  };

  const handleImport = async () => {
    // Set loading state to true before starting the import
    setLoading(true);

    // Call the function to upload the CSV data and wait for it to complete
    await uploadGenericFibreCsv(importArray);

    // Set loading state to false after the import is complete
    setLoading(false);

    // Show an alert to the user indicating that the CSV import is complete
    // eslint-disable-next-line no-alert
    window.alert(
      "CSV Imported. You will get an email with the result of the import."
    );
  };

  function convertProductType() {
    // Find the product type that matches the selectedProductType ID
    const productType = productTypes.find(
      type => type.id === selectedProductType
    );

    // Convert the product type name to lowercase
    const lowercaseString = productType.name.toLowerCase();

    // Replace spaces with underscores in the lowercase string
    const resultString = lowercaseString.replace(/ /g, "_");

    // Return the formatted string
    return resultString;
  }

  return (
    <div>
      <h1>Import Fibre Activity</h1>
      <Button
        startIcon={<ArrowBack />}
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <div style={styles.inputContainer}>
        <Typography style={{ marginBottom: 15 }}>
          Selected Product Type:
        </Typography>
        <Select
          xs={12}
          required
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          options={formattedProductTypes}
          onChange={e => {
            setSelectedProductType(e.value);
          }}
          placeholder="Select Product Type"
        />
      </div>
      {availableSuppliers.length === 0 && (
        <div style={{ marginBottom: 20 }}>
          <Typography>No available suppliers.</Typography>
        </div>
      )}
      {selectedProductType && availableSuppliers.length > 0 && (
        <div style={styles.inputContainer}>
          <Typography style={{ marginBottom: 15 }}>Select Supplier:</Typography>
          <Select
            xs={12}
            required
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            options={availableSuppliers}
            onChange={e => {
              setSelectedSupplier(e.value);
            }}
            placeholder="Select Supplier"
          />
        </div>
      )}
      <div>
        {loading && (
          <div style={{ marginBottom: 30 }}>
            <LinearProgress />
          </div>
        )}
        <Importer
          restartable
          processChunk={async rows => {
            rows.forEach(row => {
              tempArray.push({
                zawadi_agent_code_2: "",
                agent_identification_1: row.agent_identification_1,
                order_number_1: row.order_number_1,
                customer_onboarding_status_0:
                  row.customer_onboarding_status_0 || "",
                customer_current_status_0: row.customer_current_status_0 || "",
                customer_details_account_number_1:
                  row.customer_details_account_number_1,
                customer_details_name_0: row.customer_details_name_0 || "",
                customer_details_phone_0: row.customer_details_phone_0 || "",
                customer_details_email_0: row.customer_details_email_0 || "",
                customer_details_address_0:
                  row.customer_details_address_0 || "",
                order_created_date_1: row.order_created_date_1,
                order_paid_date_1: row.order_paid_date_1,
                order_installation_date_1: row.order_installation_date_1,
                order_activation_date_2: "",
                unique_premises_id_0: row.unique_premises_id_0 || "",
                supplier_1: selectedSupplier,
                product_1: row.product_1,
                order_compliance_date_2: "",
                product_type_1: convertProductType(selectedProductType),
                region_0: row.region_0,
              });
            });
            setImportArray(tempArray);
          }}
          onComplete={async () => {
            handleImport(importArray);
          }}
        >
          <ImporterField
            name="agent_identification_1"
            label="Agent Identification"
          />
          <ImporterField name="order_number_1" label="Order Number" />
          <ImporterField
            name="customer_onboarding_status_0"
            optional
            label="Customer Onboarding Status"
          />
          <ImporterField
            name="customer_current_status_0"
            optional
            label="Customer Current Status"
          />
          <ImporterField
            name="customer_details_account_number_1"
            label="Customer Details Account Number"
          />
          <ImporterField
            name="customer_details_name_0"
            optional
            label="Customer Details Name"
          />
          <ImporterField
            name="customer_details_phone_0"
            optional
            label="Customer Details Phone"
          />
          <ImporterField
            name="customer_details_email_0"
            optional
            label="Customer Details Email"
          />
          <ImporterField
            name="customer_details_address_0"
            optional
            label="Customer Details Address"
          />
          <ImporterField
            name="order_created_date_1"
            label="Order Created Date"
          />
          <ImporterField name="order_paid_date_1" label="Order Paid Date" />
          <ImporterField
            name="order_installation_date_1"
            label="Order Installation Date"
          />
          <ImporterField
            name="unique_premises_id_0"
            optional
            label="Unique Premises ID"
          />
          <ImporterField name="product_1" label="Product" />
          <ImporterField optional name="region_0" label="Region" />
        </Importer>
      </div>
    </div>
  );
}

const styles = {
  inputContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
};
